import React from "react";
import { useGlobal } from "reactn";
import SEO from "../../components/seo";
import BackgroundImage from "gatsby-background-image";
import * as Styled from "../../css/CardFlexStyle";
import "../../css/global.css";
import App from "../app";
import getLang from "../../components/scripts/getLang";
import { SortBySeason2 } from '../../components/scripts/SortBySeasons';
import Hero from '../../components/hero'

import { LostAndFoundQuery } from '../../components/scripts/graphQlQuerys/lostAndFoundQuery';
import { EnheterQuery } from '../../components/scripts/graphQlQuerys/enheterQuery';

const LostAndFound = () => {
  let [english] = useGlobal("English");
  let LostAndFoundData = getLang(english, LostAndFoundQuery());
  LostAndFoundData = SortBySeason2(LostAndFoundData);
  let { brdtext, seoTitel, seoDescription } = LostAndFoundData[0].node;

  let emailenheter = EnheterQuery().edges.filter(
    enhet => enhet.node.kvarglmtMail !== null && enhet.node.node_locale === "sv"
  );

  let render = emailenheter.map((enhet, index) => {
    return (
      <a className={'textDecorationNone'} href={"mailto:" + enhet.node.kvarglmtMail} key={index}>
        <BackgroundImage
          Tag="div"
          className={"cardFlex"}
          fluid={enhet.node.huvudBild.fluid}
          backgroundColor={`#040e18`}
        >
          <Styled.FlexH1>
            <Styled.H2>{enhet.node.namn.toUpperCase()}</Styled.H2>
            <p>{enhet.node.kvarglmtMail}</p>
          </Styled.FlexH1>
        </BackgroundImage>
      </a>
    );
  });

  return (
    <App>
      <SEO title={seoTitel} description={seoDescription} />
      <Hero introText={brdtext} heroTema={null} data={LostAndFoundData} img={'img1'} />
      <Styled.CenterDiv>
        <Styled.FlexContainer>{render}</Styled.FlexContainer>
      </Styled.CenterDiv>
    </App>
  );
};

export default LostAndFound;
