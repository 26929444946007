import React from "react";
import BackgroundImage from "gatsby-background-image";
import * as HeroStyle from "../hero/style";
import * as Styled from "../../css/CardFlexStyle";
import * as Global from "../../css/globalStyle";
import * as Style from "../../css/templateStyle";
import PictureFilter from "../../components/scripts/pictureFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const Hero = ({ introText, heroTema, data, img }) => {
  let { heroRubrik, heroUnderRubrik, rubrik, lnkHemsida, namn, brdtext } = data[0].node;
  if (heroTema !== null) {
    heroRubrik = heroTema[0].node.rubrik ? heroTema[0].node.rubrik : heroRubrik;
    heroUnderRubrik = heroTema[0].node.heroUnderRubrik
      ? heroTema[0].node.heroUnderRubrik
      : heroUnderRubrik;
    data = heroTema ? heroTema : data;
  }

  if(data[0].node.heroBild
      && data[0].node.heroBild.file
      && data[0].node.heroBild.file.contentType === 'video/mp4') {
    return (
    <a className="textDecorationNone" >
      <Global.BackgroundVideo>
        <video loop autoPlay muted playsInline>
          <source src={data[0].node.heroBild.file.url} type="video/mp4" />
        </video>
      </Global.BackgroundVideo>
    </a>
    )
  }

  if(img === "img2") { 
    return(
    <BackgroundImage
      Tag="section"
      className={"hero"}
      fluid={PictureFilter(img, data[0])}
      backgroundColor={`#040e18`}
    >
      <Global.HeroContainer>
        <Style.ArrowBack background={true}>
          <FontAwesomeIcon
            color={"white"}
            icon={faArrowLeft}
            onClick={() => window.history.back()}
          />
        </Style.ArrowBack>

        <Global.HeroText>
          <a
            className="textDecorationNone"
            href={lnkHemsida ? lnkHemsida : null}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "white" }}
          >
            <h1> {namn} </h1>
            <p>{brdtext.brdtext}</p>
          </a>
        </Global.HeroText>
      </Global.HeroContainer>
    </BackgroundImage>
    )
  } else {
    return (
      <>
        <BackgroundImage
          Tag="section"
          className={"hero"}
          fluid={PictureFilter(img, data[0])}
          backgroundColor={`#040e18`}
        >
          <HeroStyle.Container>
            <HeroStyle.Text>
              <h1> {heroRubrik || rubrik} </h1>
              <p>{heroUnderRubrik.heroUnderRubrik}</p>
            </HeroStyle.Text>
          </HeroStyle.Container>
        </BackgroundImage>
        {introText && (
          <Styled.Container
            dangerouslySetInnerHTML={{
              __html: introText.childContentfulRichText.html
            }}
          />
        )}
      </>
    );
  }
};

export default Hero;
