import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to left bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.7)
  );
  @media screen and (max-width: 500px) {
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    );
  }
`;

export const Text = styled.div`
  position: absolute;
  bottom: 30px;
  text-shadow: 1px 1px 3px #000000, 0px 0px 0px rgba(0, 0, 0, 0.3);
  width: 70%;
  padding-left: 2%;
  color: #f8f8f8;

  h1 {
    text-transform: uppercase;
    margin: -20px 0;
    padding: 20px 0;
    font-size: 7em !important;
    filter: drop-shadow(2px 4px 6px black);
    margin: -20px 0;
    padding: 20px 0 !important;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  p {
    text-decoration: none;
    color: #f8f8f8;
    margin: 0;
    font-size: 2.5vw;
    width: 85%;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  @media only screen and (max-width: 800px) and (orientation: landscape){
    width: 80%;
    h1 {
      width: 96%;
      margin: auto;
      font-size: 10vw !important;
      overflow: hidden;
    }
    p {
      margin:auto;
      font-size:1.2em;
      width: 100%;
    }
  } 

  @media screen and (max-width: 500px) and (orientation: portrait){
    width: 100%;
    margin: auto;
    position: absolute;
    padding: 0;
    top:30vh;
    text-align: center;
    h1 {
      width: 96%;
      margin: auto;
      font-size: 12vw !important;
      overflow: hidden;
    }
    p {
      margin:auto;
      font-size:1.5em;
    }
  }
  @media only screen and (min-width: 500px) and (max-width: 1200px) and (orientation: portrait) {
    width: 100%;
    margin: auto;
    padding: 0;
    h1 {
      margin: 1% 2.5%;
      font-size: 6.5em !important;
    }
    p {
      font-size: xx-large;
      width: 90%;
      margin: 1% 2.5%;
      display: none;
    }
  }
`;