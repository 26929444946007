import { graphql, useStaticQuery } from "gatsby";

export const LostAndFoundQuery = () => {
  let query = useStaticQuery(
    graphql`
    query {
      allContentfulLostAndFoundSida {
        edges {
          node {
            node_locale
            rubrik
            seoTitel
            seoDescription
            heroUnderRubrik {
              heroUnderRubrik
            }
            brdtext {
              childContentfulRichText {
                html
              }
            }
            heroBild {
              fluid(quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
    `
  );

  return query.allContentfulLostAndFoundSida
};
