const PictureFilter = (condition,item) => {
    if(condition === 'img1'){
        // condition is === img1 pick huvudBild
            if(item.node.huvudBild){
                return item.node.huvudBild.fluid
            }
             else if(item.node.heroBild.fluid){
            
                return item.node.heroBild.fluid
            }
    }else{
        // condition is not img1 pick2 huvudBild.If huvudBild2 null pick huvudBild
        if(item.node.huvudBild2){
            
            return item.node.huvudBild2.fluid
        }else{
            return item.node.huvudBild.fluid
        }
    }
}

export default PictureFilter;